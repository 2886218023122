import { IntlShape, ReactIntlErrorCode, createIntl, createIntlCache } from "react-intl";
import { apiClient } from "utils/apiClient";
import { Data, Locales } from "./types";

let intl: IntlShape;

const cache = createIntlCache();

export function initialiseIntl(locale: Locales) {
	const inst = createIntl(
		{
			locale,
			messages: getMessages(locale),
			onError,
		},
		cache,
	);
	intl = inst;
	return inst;
}

function onError(e) {
	if ((e.code = ReactIntlErrorCode.MISSING_DATA)) {
		return;
	}
	console.warn(e);
}

export async function updateIntl({ locale, organizationId, roomTemplateId }: Data) {
	let messages = {};
	if (organizationId && roomTemplateId) {
		// For now translations with roomTemplate and org are mandatory
		messages = await fetchTranslations(organizationId, locale, roomTemplateId);
	}
	const inst = createIntl(
		{
			locale,
			messages: { ...messages, ...getMessages(locale) },
			onError,
		},
		cache,
	);
	intl = inst;
	return inst;
}

export function getIntl() {
	if (!intl) {
		throw new Error("intl hasn't been initalized!");
	}

	return intl;
}

function getMessages(locale: string) {
	return require(`./${locale}.json`);
}

const fetchTranslations = async (organizationId: string, locale: string, roomTemplateId: string) => {
	const results = await apiClient(`translations/${organizationId}`, { data: { roomTemplateId, locale } });
	return results;
};
