import { adaptV4Theme, createTheme } from "@mui/material/styles";

export const globalTheme = createTheme(
	adaptV4Theme({
		typography: {
			allVariants: {
				color: "#3C4255",
			},
			h5: { fontFamily: "kallisto" },
			fontFamily: "Montserrat",
			fontWeightRegular: 500,
		},
		palette: {
			common: {
				black: "black",
				white: "white",
			},
		},
		overrides: {
			MuiPaper: {
				elevation0: {
					boxShadow: "0px 15px 40px #00000019",
				},
			},
		},
	}),
);
