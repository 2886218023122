import Quill from 'quill';
import Delta from 'quill-delta';
import Parchment from 'parchment';
import defaultsDeep from 'lodash/defaultsDeep';
import defaultOptions, { MediaResizeModule, MediaResizeOptions } from './Options';
import { SizeInfo } from './modules/SizeInfo';
import { Toolbar } from './modules/Toolbar';
import { Resize } from './modules/Resize';
import { BaseModule } from './modules/BaseModule';

const knownModules = { SizeInfo: SizeInfo, Toolbar, Resize };

/**
 * Custom module for quilljs to allow user to resize media (img, video, audio) elements
 * (Works on Chrome, Edge, Safari and replaces Firefox's native resize behavior)
 * @see https://quilljs.com/blog/building-a-custom-module/
 */
export default class MediaResize {
    quill: Quill;
    options: MediaResizeOptions;
    element: HTMLElement | null = null;
    overlay: HTMLElement | null = null;
    parchment: typeof Parchment | null = Parchment;
    modules: BaseModule[] = [];
    moduleClasses: any = null;
    timeoutID: any = null;

    constructor(quill: Quill, options?: MediaResizeOptions) {
        // save the quill reference and options
        this.quill = quill;

        // Apply the options to our defaults, and stash them for later
        // defaultsDeep doesn't do arrays as you'd expect, so we'll need to apply the classes array from options separately
        let moduleClasses: MediaResizeModule[] = [];
        if (options?.modules) {
            moduleClasses = [...options?.modules];
        }

        if (options?.parchment) {
            this.parchment = options?.parchment;
        }

        // Apply options to default options
        this.options = defaultsDeep({}, options ?? {}, defaultOptions);

        // (see above about moduleClasses)
        if (!!moduleClasses) {
            this.options.modules = moduleClasses;
        }

        // respond to clicks inside the editor
        this.quill?.root.addEventListener('click', this.handleClick, false);

        if (this.quill?.root.parentElement) {
            this.quill.root.parentElement.style.position = this.quill?.root.parentElement.style.position || 'relative';
        }

        // setup modules
        this.moduleClasses = this.options.modules;

        this.modules = [];
    }

    initializeModules = () => {
        this.destroyModules();

        if (this.options.modules) {
            this.modules = this.options.modules
                ?.filter(moduleClassName => typeof moduleClassName === 'string' && knownModules[moduleClassName])
                .map((moduleClassName: string) => new knownModules[moduleClassName](this) as BaseModule)

            this.modules.forEach(
                (module: BaseModule) => {
                    module.onCreate(this.parchment);
                },
            );

            this.requestUpdate();
        }
    };

    requestUpdate = () => {
        this.repositionOverlayAndElement();
        if (this.timeoutID) clearTimeout(this.timeoutID);
        this.timeoutID = setTimeout(() => {
            this.onUpdate();
        }, 200);
    };

    onUpdate = () => {
        this.modules.forEach(
            (module) => {
                module.onUpdate();
            },
        );
        this.quill.updateContents(new Delta().retain(1), 'user');
    }

    destroyModules = () => {
        this.modules.forEach(
            (module) => {
                module.onDestroy();
            },
        );

        this.modules = [];
    };

    handleClick = (evt) => {
        // TODO: add handler for clicking video or audio
        if (evt.target && evt.target.tagName && evt.target.tagName.toUpperCase() === 'IMG') {
            if (this.element === evt.target) {
                // we are already focused on this image
                this.show(evt.target)
                return;
            }
            if (this.element) {
                // we were just focused on another image
                this.hide();
            }
            // clicked on an image inside the editor
            this.show(evt.target);
        } else if (this.element) {
            // clicked on a non image
            this.hide();
        }
    };

    show = (img) => {
        // keep track of this img element
        this.element = img;

        this.showOverlay();

        this.initializeModules();
    };

    showOverlay = (force = false) => {
        if (this.overlay && !force) {
            this.hideOverlay();
        }

        // set cursor to the beginning of the textarea
        // to avoid keyboard event to delete without removing the overlay.
        this.quill?.setSelection({ index: 0, length: 0 });

        // prevent spurious text selection
        this.setUserSelect('none');

        // listen for the image being deleted or moved
        document.addEventListener('keyup', this.onCancel, true);
        this.quill?.root.addEventListener('input', this.onCancel, true);

        // Create and add the overlay
        this.overlay = document.createElement('div');
        Object.assign(this.overlay.style, this.options.overlayStyles);

        this.quill.root.parentElement?.appendChild(this.overlay);

        this.repositionOverlayAndElement();
    };

    hideOverlay = () => {
        if (this.overlay) {
            // Remove the overlay
            this.quill?.root.parentElement?.removeChild(this.overlay);
        }
        this.overlay = null;

        // stop listening for image deletion or movement
        document.removeEventListener('keyup', this.onCancel);
        this.quill?.root.removeEventListener('input', this.onCancel);

        // reset user-select
        this.setUserSelect('');
    };

    repositionOverlayAndElement = () => {
        if (!this.overlay || !this.element) {
            return;
        }

        // position the overlay over the media
        const parent = this.quill?.root.parentElement;
        const targetMediaRect = this.element.getBoundingClientRect();
        if (!parent) return;
        const containerRect = parent.getBoundingClientRect();

        // set overlay position and dimension to media size
        Object.assign(this.overlay.style, {
            left: `${targetMediaRect.left - containerRect.left - 1 + parent.scrollLeft}px`,
            top: `${targetMediaRect.top - containerRect.top + parent.scrollTop}px`,
            width: `${targetMediaRect.width}px`,
            height: `${targetMediaRect.height}px`,
        });
    };

    /**
     * Hide overlay and destroy modules
     */
    hide = () => {
        this.hideOverlay();
        this.destroyModules();
    };

    setUserSelect = (value) => {
        [
            'userSelect',
            'mozUserSelect',
            'webkitUserSelect',
            'msUserSelect',
        ].forEach((prop) => {
            // set on contenteditable element and <html>
            this.quill.root.style[prop] = value;
            document.documentElement.style[prop] = value;
        });
    };

    /**
     * Listen to keyboard events and cancel the media resize on delete or backspace
     * @param evt 
     */
    onCancel = (evt) => {
        // @ts-ignore
        if (!!this.element && !!this.quill) {
            // listen to keyboard events: delete, backspace, escape
            if (evt.keyCode === 46 || evt.keyCode === 8) {
                // @ts-ignore
                this.quill?.find?.(this.element).deleteAt(0);
            }
            this.hide();
        }
    };
}
