import { Helmet } from "react-helmet";

export default function Hubspot() {
	if (!Boolean(process.env.REACT_APP_HUBSPOT_ID)) {
		return null;
	}
	return (
		<Helmet>
			<script>
				{`
                var _hsq = (window._hsq = window._hsq || []);
                _hsq.push(["setPath", window.location.pathname + window.location.search + window.location.hash]);
            `}
			</script>

			{/* <!-- Start of HubSpot Embed Code --> */}
			<script
				type="text/javascript"
				id="hs-script-loader"
				async
				defer
				src={`//js-eu1.hs-scripts.com/${process.env.REACT_APP_HUBSPOT_ID}.js`}
			></script>

			<script>
				{`
					window.hsConversationsSettings = {
						loadImmediately: false,
						enableWidgetCookieBanner: true,
						disableAttachment: true,
					}`}
			</script>
			{/* <!-- End of HubSpot Embed Code --> */}
		</Helmet>
	);
}
