import format from "date-fns/format";
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { IntlShape, RawIntlProvider } from "react-intl";
import { useAsyncFn } from "react-use";
import { initialiseIntl, updateIntl } from "./intl";
import { Data, Locales } from "./types";
import { getDefaultLocale, localeDateFnsMap, updateLocale } from "./utils";

interface ContextValue {
	locale: Locales;
	updateTranslations: (data: Data) => void;
	dateFnsLocale: Locale;
}
const ctx = createContext<ContextValue | undefined>(undefined);

export const LocalizationProvider: React.FC<{ defaultLocale?: Locales }> = ({
	defaultLocale = getDefaultLocale(),
	children,
}) => {
	const [data, setData] = useState<Data>({ locale: defaultLocale });
	const [dateFnsLocale, setdateFnsLocale] = useState<Locale>(localeDateFnsMap[defaultLocale]);
	const [intlInstance, setIntlInstance] = useState<IntlShape>(initialiseIntl(defaultLocale));

	const [, doFetch] = useAsyncFn(async (data) => {
		const instance = await updateIntl(data);
		setdateFnsLocale(localeDateFnsMap[data.locale]);
		setIntlInstance(instance);
		console.info("Messages have been set with data", { data });
	}, []);

	useEffect(() => {
		doFetch(data);
	}, [data, doFetch]);

	const updateTranslations = useCallback(({ locale, organizationId, roomTemplateId }) => {
		setData({ locale, organizationId, roomTemplateId });
		updateLocale(locale);
	}, []);

	const ctxValue = useMemo(() => {
		return { locale: data.locale, dateFnsLocale, updateTranslations };
	}, [data, dateFnsLocale, updateTranslations]);

	return (
		<ctx.Provider value={ctxValue}>
			<RawIntlProvider value={intlInstance} key={data.locale}>
				{children}
			</RawIntlProvider>
		</ctx.Provider>
	);
};

export const useLocalizationContext = () => {
	const context = useContext(ctx);
	if (!context) {
		throw new Error("No provider");
	}
	return context;
};

export const useFormatDate = () => {
	const { dateFnsLocale } = useLocalizationContext();
	return (date: number | string | Date, dateFormat: string) =>
		format(new Date(date), dateFormat, { locale: dateFnsLocale });
};
