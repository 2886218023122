import Hotjar from "@hotjar/browser";

const hotjarSiteId: number = Number(process.env.REACT_APP_HOTJAR_ID); // TODO: Move to .env when needed
const hotjarVersion = 6;

export function initAnalytics() {
	if (!isNaN(hotjarSiteId)) {
		// Init hotjar
		Hotjar.init(hotjarSiteId, hotjarVersion);
	}
}
