import { Quill } from "react-quill";
import MediaResize from "./media-resize/MediaResize";
import "react-quill/dist/quill.snow.css";

const BlockEmbed = Quill.import("blots/block/embed");

class AudioBlot extends BlockEmbed {
	static create(src) {
		let node = super.create();
		node.setAttribute("src", src);
		node.setAttribute("style", "margin: auto; display: block;");
		node.setAttribute("controls", "");
		return node;
	}

	static value(node) {
		return node.getAttribute("src");
	}
}
AudioBlot.blotName = "audio";
AudioBlot.tagName = "audio";

class VideoBlot extends BlockEmbed {
	static create(src) {
		let node = super.create();
		node.setAttribute("src", src);
		node.setAttribute("style", "margin: auto; width: 100%; max-width: 600px; display: block;");
		node.setAttribute("controls", "");
		return node;
	}

	static value(node) {
		return node.getAttribute("src");
	}
}
VideoBlot.blotName = "video";
VideoBlot.tagName = "video";

class ImageBlot extends BlockEmbed {
	static create(el: HTMLImageElement) {
		let node = super.create();
		node.setAttribute("src", el.getAttribute("src"));
		node.setAttribute("style", el.getAttribute("style"));
		node.setAttribute("width", el.getAttribute("width"));
		return node;
	}

	static value(node: HTMLImageElement) {
		return node;
	}
}

ImageBlot.blotName = "image";
ImageBlot.tagName = "img";

export const setupReactQuill = () => {
	Quill.register('modules/mediaResize', MediaResize);

	Quill.register(AudioBlot, true);

	Quill.register(VideoBlot, true);

	Quill.register(ImageBlot, true);
};
