import { useIntl } from "react-intl";
import { Alert, AlertTitle } from "@mui/lab";
import { ErrorBoundary } from "react-error-boundary";
import { makeStyles } from "@mui/styles";
import { ReactComponent as RiddlestoryLogo } from "components/logo.svg";
import { Container } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { logError } from "../setupSentry";

const useStyles = makeStyles(() => ({
	root: {
		height: "100vh",
		display: "flex",
		alignItems: "center",
		flexErap: "wrap",
		justifyContent: "center",
		alignContent: "center",
		flexDirection: "column",
	},
	alert: {
		marginTop: "3rem",
		boxShadow: "none",
	},
}));

function ErrorFallback({ error }) {
	const classes = useStyles();
	const intl = useIntl();
	return (
		<Container maxWidth="sm" fixed className={classes.root}>
			<RiddlestoryLogo />
			<Container maxWidth="sm" className={classes.alert}>
				{error.name === "SecurityError" && !navigator.cookieEnabled ? (
					<Alert variant="outlined" severity="warning">
						<AlertTitle>
							<b>
								{intl.formatMessage({
									id: "ERROR-BOUNDARY.index.warning",
								})}
							</b>
						</AlertTitle>
						<FormattedMessage
							id="GLOBAL.cookies.disabled"
							values={{
								bold: (str) => {
									return <b>{str}</b>;
								},
							}}
						/>
					</Alert>
				) : (
					<Alert variant="outlined" severity="error">
						<AlertTitle>
							{intl.formatMessage({
								id: "ERROR-BOUNDARY.index.something_went_wrong",
							})}
						</AlertTitle>
						{error.message}
					</Alert>
				)}
			</Container>
		</Container>
	);
}

export const AppErrorBoundary = ({ children }) => {
	return (
		<ErrorBoundary onError={logError} FallbackComponent={ErrorFallback}>
			{children}
		</ErrorBoundary>
	);
};

export default AppErrorBoundary;
