let isConversationReady = false;

function getHsq() {
	/**
	 * Load the HubSpot tracking code.
	 */
	return (window._hsq = window._hsq || []);
}

getHsq();

/**
 * Identify a user by their email address and name.
 * @param identity The user's email address and name.
 */
export function identifyUser(identity: { email: string }) {
	getHsq().push(["identify", identity]);
	trackPageView(); // Identify the user on the first page view. Otherwise the identify call is ignored.
}

export function removeIdentity() {
	// Remove hubspotutk and messagesUtk cookie
	document.cookie = "hubspotutk=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

	// Clear the cookies related to Conversations API
	document.cookie = "hubspotapi-csrf=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
	document.cookie = "messagesUtk=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
	clearHubspotConversations();
	removeHubspotConversations();
}

/**
 * Track a custom event.
 */
export function trackEvent(eventName: string, properties: any) {
	getHsq().push(["trackCustomBehavioralEvent", { name: eventName, properties }]);
}

/**
 * Track a page view.
 */
export function trackPageView() {
	getHsq().push(["setPath", window.location.pathname + window.location.search]);
	getHsq().push(["trackPageView"]);
}

async function onConversationsAPIReady() {
	window.HubSpotConversations.widget.load();
	isConversationReady = true;
}

/**
 * Load the HubSpot Conversations API.
 */
export function loadHubspotConversations() {
	/*
       If external API methods are already available, use them.
      */
	if (window.HubSpotConversations) {
		onConversationsAPIReady();
	} else {
		/*
          Otherwise, callbacks can be added to the hsConversationsOnReady on the window object.
          These callbacks will be called once the external API has been initialized.
        */
		window.hsConversationsOnReady = [onConversationsAPIReady];
	}
}

/**
 * Open the HubSpot Conversations widget.
 */
export function openHubspotConversations() {
	if (isConversationReady) window.HubSpotConversations.widget.open();
}
/**
 * Close the HubSpot Conversations widget.
 */
export function closeHubspotConversations() {
	if (isConversationReady) window.HubSpotConversations.widget.close();
}
/**
 * Remove the HubSpot Conversations widget.
 */
export function removeHubspotConversations() {
	if (isConversationReady) window.HubSpotConversations.widget.remove();
}

/**
 * Clear the HubSpot Conversations widget.
 */
export function clearHubspotConversations() {
	if (isConversationReady) window.HubSpotConversations.clear({ resetWidget: true });
}

declare global {
	interface Window {
		_hsq: any[];
		HubSpotConversations: any;
		hsConversationsSettings: any;
		hsConversationsOnReady: any[];
	}
}
