import { Alert } from "@mui/lab";
import { ReactComponent as RiddlestoryLogo } from "components/logo.svg";
import { makeStyles } from "@mui/styles";
import { Container } from "@mui/material";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles(() => ({
	root: {
		height: "100vh",
		display: "flex",
		alignItems: "center",
		flexErap: "wrap",
		justifyContent: "center",
		alignContent: "center",
		flexDirection: "column",
	},
	alert: {
		marginTop: "3rem",
		boxShadow: "none",
	},
}));

const MobileView: React.FC = () => {
	const classes = useStyles();
	return (
		<Container maxWidth="sm" fixed className={classes.root}>
			<RiddlestoryLogo />
			<Container maxWidth="sm" className={classes.alert}>
				<Alert variant="outlined" severity="info">
					<FormattedMessage id="GLOBAL.mobile.view.not.supported" />
				</Alert>
			</Container>
		</Container>
	);
};

export default MobileView;
