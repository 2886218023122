import { SnackbarProvider } from "notistack";

export default function Toaster({ children }) {
	return (
		<SnackbarProvider
			maxSnack={3}
			autoHideDuration={2000}
			anchorOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
		>
			{children}
		</SnackbarProvider>
	);
}
