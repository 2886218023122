import * as Sentry from "@sentry/react";
import { Extras } from "@sentry/types";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import { useEffect } from "react";

const isLocal = process.env.NODE_ENV === "development";

export function initSentry() {
	if (isLocal) {
		return;
	}
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		integrations: [
			new Sentry.BrowserTracing({
				// Can also use reactRouterV3Instrumentation or reactRouterV4Instrumentation
				routingInstrumentation: Sentry.reactRouterV6Instrumentation(
					useEffect,
					useLocation,
					useNavigationType,
					createRoutesFromChildren,
					matchRoutes,
				),
			}),
		],
		release: process.env.REACT_APP_GIT_SHA,
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 0.1,
		environment: process.env.NODE_ENV,
	});
}

export function logError(error: any, errorInfo: Extras | null = null) {
	if (isLocal) {
		return;
	}
	Sentry.withScope((scope) => {
		errorInfo && scope.setExtras(errorInfo);
		Sentry.captureException(error);
	});
}
