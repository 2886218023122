import queryString from "query-string";

export const apiURL = process.env.REACT_APP_API_URL || window.location.origin + "/api";

export type ApiClientPayload = {
	data?: any;
	params?: any;
	token?: string | null;
	headers?: Record<string, string>;
	formData?: any;
	signal?: any;
	method?: "GET" | "POST" | "PUT" | "DELETE";
	getRawResponse?: boolean;
};

export async function apiClient(
	endpoint: string,
	{ data, token, method, headers: customHeaders, formData, signal, getRawResponse, params }: ApiClientPayload = {},
) {
	const config: RequestInit = {
		method: method ?? (data || formData ? "POST" : "GET"),
		body: data ? JSON.stringify(data) : formData ? formData : undefined,
		signal: signal,
		// @ts-ignore
		headers: {
			Authorization: token ? `Bearer ${token}` : undefined,
			...(data && { "Content-Type": "application/json" }),
			...customHeaders,
		},
	};

	const _sanitizedParams = { ...params };
	Object.keys(_sanitizedParams).forEach((key) => {
		if (params[key] === null || params[key] === undefined) {
			delete params[key];
		}
	});

	const _params = queryString.stringify(_sanitizedParams, { arrayFormat: 'bracket' });
	if (_params.toString()) {
		endpoint += "?" + _params.toString();
	}
	const response = await window.fetch(`${apiURL}/${endpoint}`, config);
	// Session expired
	if (response.status === 401) {
		// TODO: Logout
		window.localStorage.clear();
		// refresh the page for them
		if (window.location.pathname.includes("admin/room")) {
			window.close();
		}
		window.location.reload();
		return Promise.reject({ message: "Please re-authenticate." });
	}

	if (response.status === 204) {
		return;
	}

	if (getRawResponse) {
		return response;
	}

	if (formData) {
		if (response.ok) {
			return response;
		} else {
			console.log("REJECT");
			const json = await response.json();
			return Promise.reject({ status: response.status, response, json });
		}
	}

	const json = await response.json();
	if (response.ok) {
		return json;
	} else {
		console.log("REJECT", json);
		return Promise.reject({ json, status: response.status, response });
	}
}
