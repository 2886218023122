import { BaseModule } from "./BaseModule";
import { renderToString } from "react-dom/server";
import { ReactComponent as IconAlignLeft } from "quill/assets/icons/align-left.svg";
import { ReactComponent as IconAlignCenter } from "quill/assets/icons/align-center.svg";
import { ReactComponent as IconAlignRight } from "quill/assets/icons/align-right.svg";

export const POS_LEFT_MARGIN = "0 auto 0 0";
export const POS_CENTER_MARGIN = "0 auto";
export const POS_RIGHT_MARGIN = "0 0 0 auto";

export class Toolbar extends BaseModule {
	toolbar: any = null;
	alignments: any = [];
	Parchment: any = {};
	MarginStyle: any = {};
	DisplayStyle: any = {};

	onCreate = (parchment: any) => {
		// Initilize styles
		this.Parchment = parchment;
		this.MarginStyle = new parchment.Attributor.Style("margin", "margin");
		this.DisplayStyle = new parchment.Attributor.Style("display", "display");

		// Setup Toolbar
		this.toolbar = document.createElement("div");
		Object.assign(this.toolbar.style, this.options.toolbarStyles);
		this.overlay.appendChild(this.toolbar);

		// Setup Buttons
		this._defineAlignments();
		this._addToolbarButtons();
	};

	// The toolbar and its children will be destroyed when the overlay is removed
	onDestroy = () => {};

	// Nothing to update on drag because we are are positioned relative to the overlay
	onUpdate = () => {};

	_defineAlignments = () => {
		this.alignments = [
			{
				icon: <IconAlignLeft />,
				apply: () => {
					this.DisplayStyle.add(this.element, "block");
					this.MarginStyle.add(this.element, POS_LEFT_MARGIN);
				},
				isApplied: () => (this.element ? this.MarginStyle.value(this.element) === POS_LEFT_MARGIN : false),
			},
			{
				icon: <IconAlignCenter />,
				apply: () => {
					this.DisplayStyle.add(this.element, "block");
					this.MarginStyle.add(this.element, POS_CENTER_MARGIN);
				},
				isApplied: () => (this.element ? this.MarginStyle.value(this.element) === POS_CENTER_MARGIN : false),
			},
			{
				icon: <IconAlignRight />,
				apply: () => {
					this.DisplayStyle.add(this.element, "block");
					this.MarginStyle.add(this.element, POS_RIGHT_MARGIN);
				},
				isApplied: () => (this.element ? this.MarginStyle.value(this.element) === POS_RIGHT_MARGIN : false),
			},
		];
	};

	_addToolbarButtons = () => {
		const buttons: any = [];
		this.alignments.forEach((alignment, idx) => {
			const button = document.createElement("span");
			buttons.push(button);

			button.innerHTML = renderToString(alignment.icon);
			button.addEventListener("click", () => {
				// deselect all buttons
				buttons.forEach((button) => (button.style.filter = ""));
				if (alignment.isApplied()) {
					// If applied, unapply
					this.MarginStyle.remove(this.element);
					this.DisplayStyle.remove(this.element);
				} else {
					// otherwise, select button and apply
					this._selectButton(button);
					alignment.apply();
				}
				// image may change position; redraw drag handles
				this.requestUpdate();
			});
			Object.assign(button.style, this.options.toolbarButtonStyles);
			if (idx > 0) {
				button.style.borderLeftWidth = "0";
			}
			// @ts-ignore
			Object.assign(button.style, this.options.toolbarButtonSvgStyles);
			if (alignment.isApplied()) {
				// select button if previously applied
				this._selectButton(button);
			}
			this.toolbar.appendChild(button);
		});
	};

	_selectButton = (button) => {
		button.style.filter = "invert(10%)";
	};
}
