import React, { Suspense, lazy, useEffect } from "react";
import { Route, BrowserRouter as Router, Routes, useLocation } from "react-router-dom";
import { isDesktop, isMobile } from "react-device-detect";
import MobileView from "mobile-view";
import { useMediaQuery } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { withProfiler } from "@sentry/react";

import Toaster from "components/toasters";

import { removeHubspotConversations, trackPageView } from "utils/hubspot";

const InitializeGameApp = lazy(() => import("InitializeGameApp"));
const InitializeAdminApp = lazy(() => import("InitializeAdminApp"));

const App: React.FC = () => {
	return (
		<Router>
			<MainApp />
		</Router>
	);
};

const MainApp = () => {
	const location = useLocation();
	const matches = useMediaQuery("(min-width:960px)", { defaultMatches: true });

	const areResponsive = ["user-dashboard"];
	const isPathResponsive = areResponsive.some((substring) => location.pathname.includes(substring));

	const shouldRenderAppRoutes = (matches && isDesktop) || isMobile || isPathResponsive;

	useEffect(() => {
		// track page view for hubspot
		trackPageView();

		if (!location.pathname.includes("subscription-plan")) {
			removeHubspotConversations();
		}
	}, [location]);

	if (shouldRenderAppRoutes) {
		return (
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<Toaster>
					<Suspense fallback={<LinearProgress />}>
						<Routes>
							<Route path="/admin/*" element={<InitializeAdminApp />} />
							<Route path="/*" element={<InitializeGameApp />} />
						</Routes>
					</Suspense>
				</Toaster>
			</LocalizationProvider>
		);
	}

	return <MobileView />;
};

export default withProfiler(App);
